import {SwabKitOrderModel} from '@matchsource/models/swab-kit-order';

export interface SwabKitOrderModelDisplayItem {
  order?: Partial<SwabKitOrderModel>;
  // Parent-child relation for collapsible rows in the grid
  id?: number;
  parentId?: number;
  shippingInfo?: ShippingInfoItem;
}

export enum ShippingInfoItemType {
  Text = 'Text',
  PhoneNumber = 'PhoneNumber',
  BpGuid = 'BpGuid',
}

export interface ShippingInfoItem {
  text: string;
  type: ShippingInfoItemType;
}

export const PATIENT_SWAB_KIT_ORDERS_ROUTE_NAME = 'patient-swab-kit-orders';
export const BULK_SWAB_KIT_ORDERS_ROUTE_NAME = 'bulk-swab-kit-orders';
